import React from 'react';
import './general.css'; // Import the general.css file
import './output.css'; // Import the output.css file
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tooltip, Spinner, IconButton } from "@material-tailwind/react";
import './index.css';
import RoboSvg from './chatbot-mascot-icon.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faBars, faChartBar, faChartLine, faChartPie, faCog, faDatabase, faDoorOpen, faFolder, faHamburger, faHeart, faPaperPlane, faPenToSquare, faPlus, faQuestionCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Chart, registerables } from 'chart.js';
import { Bar, Line, Pie } from "react-chartjs-2";
import ReactTable from './Container/Table';

Chart.register(...registerables);




const Typewriter = ({ text }) => {
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentText((prevText) => {
        const nextCharIndex = prevText.length;
        const nextChar = text.charAt(nextCharIndex);
        const newText = prevText + nextChar;
        return newText;
      });
    }, 100);

    return () => clearTimeout(timeout);
  }, [currentText, text]);




  return <span>{currentText}</span>;
};

export function App() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPredefinedCards, setShowPredefinedCards] = useState(true);
  const messagesEndRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [selectedChart, setSelectedChart] = useState('line'); // Initialize with a default value
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  


  // const LLMAPI = "https://dataaibot-web01.azurewebsites.net/llm"
  const LLMAPI = "https://us-central1-digi-drip.cloudfunctions.net/on_req_llm"
  // const LLMAPI = "http://192.168.1.50:8000/llm"

  const handleMessageSend = async () => {
    if (newMessage.trim() !== '') {
      const message = {
        content: newMessage,
        sender: 'User 2', // Assuming current user is User 1
      };
      setMessages([...messages, message])
      setIsLoading(true)
      setNewMessage('');
      try {
        let mess = JSON.stringify({ query: message.content, type: 'llm' })
        const response = await axios({
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          data: mess,

          // url: 'https://on-req-llm-sqd7roreeq-uc.a.run.app',
          url: LLMAPI
          // url: 'http://127.0.0.1:8000/llm',
        }); // Replace 'API_ENDPOINT' with your actual API endpoint
        const newMessageFromUser2 = {
          content: response.data.result,
          sql_cmd: response.data.intermediate_steps[1],
          sender: 'User 1',
          typingAnimation: true,
          sql: false,
          table: false,
          table_html: '',
          graph: false,
          graph_html: ''
        };

        setMessages([...messages, message, newMessageFromUser2]);
        console.log(messages)
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        const newMessageFromUser2 = {
          content: 'something went wrong!!',
          sender: 'User 1',
          color: 'red',
          typingAnimation: true,
        };
        setMessages([...messages, message, newMessageFromUser2]);
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleMessageSend();
    }
  };

  const handleCardClick = (text) => {
    setNewMessage(text);
  };


  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      setMessages((prevMessages) =>
          prevMessages.map((msg) => ({
            ...msg,
            typingAnimation: false,
          }))
      );
    }, 3000);

    return () => clearTimeout(typingTimeout);
  }, [messages]);

  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // useEffect(() => {
  //   if (lastMessageRef.current) {
  //     lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [messages]);


  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsLargeScreen(window.innerWidth >= 640);
  //   };

  //   handleResize(); // Call initially
  //   window.addEventListener('resize', handleResize); // Listen for window resize events
  //   return () => window.removeEventListener('resize', handleResize); // Remove event listener on unmount
  // }, []);

  // useEffect(() => {
  //   if (isLargeScreen) {
  //     setShowMenu(true)
  //   }
  // }, [isLargeScreen]);

  useEffect(() => {
    if (messages.length === 0 && showPredefinedCards) {
      setShowPredefinedCards(true);
    } else {
      setShowPredefinedCards(false)
    }
  }, [messages]);

  // const handeSql = (messageId) => {
  //   messages[messageId].sql = !messages[messageId].sql
  // }

  const handeSql = (messageId) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[messageId] = {
        ...updatedMessages[messageId],
        sql: !updatedMessages[messageId].sql,
      };
      return updatedMessages;
    });
  };

  const [tableLoading, setTableLoading] = useState(false);

  const handleHTML = async (messageId, qq) => {
    try {
      setTableLoading(true);
      const bool = messages[messageId].table_html.length !== 0;

      if (bool) {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[messageId] = {
            ...updatedMessages[messageId],
            table: !updatedMessages[messageId].table,
            graph: false
          };
          return updatedMessages;
        });
        setTableLoading(false);
      } else {
        const response = await axios({
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          data: {
            query: qq,
            type: 'table',
          },
          // url: 'https://on-req-llm-sqd7roreeq-uc.a.run.app',
          url: LLMAPI
        });
        const backendHtmlString = response.data.html;
        // console.log("here is table data...", JSON.stringify(backendHtmlString));
        console.log("here is table data...", backendHtmlString);



        const jsonData = JSON.parse(backendHtmlString);
        const columns = jsonData.length > 0 ? Object.keys(jsonData[0]).map(key => ({ Header: key, accessor: key })) : [];
        const data = jsonData.map(item => Object.values(item).reduce((acc, val, index) => ({ ...acc, [Object.keys(item)[index]]: val }), {}));



        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[messageId] = {
            ...updatedMessages[messageId],
            table: !updatedMessages[messageId].table,
            // table_html: { __html: backendHtmlString },
            columns: columns,
            data: data,
            graph: false
          };
          return updatedMessages;
        });
        setTableLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      const newMessageFromUser2 = {
        content: 'something went wrong!!',
        sender: 'User 1',
        color: 'red',
        typingAnimation: true,
      };
      setMessages([...messages, newMessageFromUser2]);
      // setIsLoading(false);
      setTableLoading(false);
    }
  };







  const handleGraph = async (messageId, qq) => {

    //handle graph button


    try {
      setGraphLoading(true);
      const bool = messages[messageId].graph_html.length !== 0;

      if (bool) {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[messageId] = {
            ...updatedMessages[messageId],
            table: false,
            graph: !updatedMessages[messageId].graph,
          };
          return updatedMessages;
        });
        setGraphLoading(false);
      } else {
        const response = await axios({
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          data: {
            query: qq,
            type: 'table',
          },
          // url: 'https://on-req-llm-sqd7roreeq-uc.a.run.app',
          url: LLMAPI,
        });
        const backendGraphImageUrl = response.data.html;
        const parsedData = JSON.parse(backendGraphImageUrl);

        const dynamicData = {
          labels: [],
          datasets: [], // Initialize datasets as an empty array
        };
        console.log("here is graph...",parsedData);

        //dyanamic fetching label from parseData
        const keys = Object.keys(parsedData[0]);
        dynamicData.labels = keys;
        console.log("keys",keys);
       

        keys.forEach(key => {
          dynamicData.datasets.push({
            label: key,
            data: parsedData.map(item => item[key]), // Use map to extract data for each key
            backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.3)`,
            borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)},  1)`,
            borderWidth: 1,
          });
        });
        console.log("dynamics",dynamicData);

        const options = {
          scales: {
            y: {
              beginAtZero: true,
            },
            x:{
              beginAtZero: true,
            }
          },
        };
        console.log("here options...",options);

        setMessages((prevMessages) => {

          const updatedMessages = [...prevMessages];
          updatedMessages[messageId] = {
            ...updatedMessages[messageId],
            table: false,
            // graph: !updatedMessages[messageId].graph,
            graph: true, // Set graph to true to keep it visible

            data: dynamicData,
            options: options
            // graph_html: image,
          };
          console.log(updatedMessages[messageId])
          return updatedMessages;
        });
        setGraphLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      const newMessageFromUser2 = {
        content: 'Something went wrong with graph generation!',
        sender: 'User 1',
        color: 'red',
        typingAnimation: true,
      };
      setMessages([...messages, newMessageFromUser2]);
      setGraphLoading(false);
    }
  };

  const exportExcel = (messageId, qq) => {
    // console.log("Export Excel")
  }

  //selected graph
  const handleButtonClick = (chartType) => {
    setSelectedChart(chartType);
  };
  

  // function refreshPage() {
  //   window.location.reload(false);
  // }

  // const [showMenu, setShowMenu] = useState(true
  // );

  // const toggleMenu = () => {
  //   console.log("I am clicked..",showMenu)
  //   setShowMenu(!showMenu);
  // };
  




  return (
    
<div className="min-h-screen bg-gradient-to-r from-purple-50 to-blue-50">
<div className="max-w-3xl mx-auto px-4 py-8 rounded-base">
  <header className="text-center mb-8">
    <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-700 md:text-5xl lg:text-6xl ">Chat with your <span className="text-blue-600 dark:text-blue-500">Enterpise Data</span> with full data privacy</h1>
  </header>

  <main className="bg-white shadow-lg rounded-2xl p-4 h-[75vh] flex flex-col justify-between align-middle">
    {/* Chat messages */}
    <div className="h-[90%] overflow-y-scroll p-5 scrollbar-thin scrollbar-thumb-white scrollbar-track-transparent">
      {showPredefinedCards && (
        <div className="flex space-x-4 space-y-4 flex-col items-center">
          <div className='text-gray-800'>
            <h4>Examples</h4>
          </div>

          <div className="flex space-x-4">
            <button
              className="bg-white border-2 hover:bg-gray-200 text-slate-700 rounded-lg px-4 py-2 focus:outline-none"
              onClick={() => handleCardClick('How many stores are in Florida?')}
            >
              How many stores are in Florida?
            </button>
            <button
              className="bg-white hover:bg-gray-200 border-2 text-slate-700 rounded-lg px-4 py-2 focus:outline-none"
              onClick={() => handleCardClick('Average household income in every state?')}
            >
              Average household income in every state?
            </button>
            <button
              className="bg-white hover:bg-gray-200 border-2 text-slate-700 rounded-lg px-4 py-2 focus:outline-none"
              onClick={() => handleCardClick('Price and number of order made my Dharma Ltd.')}
            >
              Price and number of order made my Dharma Ltd.
            </button>
          </div>
        </div>
      )}
      <div ref={messagesEndRef}/>
      {!showPredefinedCards && (
                  <div className="overflow-y-scroll p-5 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-transparent ">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            ref={index === messages.length - 1 ? lastMessageRef : null}
                            className={`flex ${message.sender === 'User 1' ? 'mb-2 ' : 'mb-6 justify-end '}  `}
                        >
                          {message.sender === 'User 1' ? (
                              <>
                                <img
                                    className="w-8 h-8 rounded-full mr-2 "
                                    src={RoboSvg}
                                    alt="User 1"
                                />
                                <div className="flex-col">
                                  <div
                                      className={`border-2 rounded-lg p-2 h-[100%]  ${message?.color === 'red' ? 'bg-red-300 text-white font-bold' : ' bg-white'}`}>
                                      <div className="text-sm typewriter mb-5">
                                        {!message.sql ? message?.typingAnimation ? (
                                                <Typewriter text={message.content} />
                                            ) : (
                                                message.content
                                            )
                                            : message?.sql_cmd?.replace(/\bLIMIT\s+\d+\b/g, '')}
                                      </div>

                                    {message.table && (
                                        <div className="text-center">
                                          <ReactTable data={message.data} columns={message.columns} />
                                        </div>
                                    )}

                                    {/* // Inside your graph container */}
                                    <div className="graph-container">
                                      <div className="text-center">
                                        {message.graph && (
                                          <div className="inline-block pr-16">
                                            {selectedChart === 'bar' && <Bar key={selectedChart} data={message.data} options={message.options} height={200} />}
                                            {selectedChart === 'pie' && <Pie key={selectedChart} data={message.data} height={200} />}
                                            {selectedChart === 'line' && <Line key={selectedChart} data={message.data} options={message.options} height={200} />}
                                          </div>
                                        )}

                                      </div>
                                    </div>


                                    <div className='flex justify-between px-2'>
                                      <button className='text-sm text-gray-600 italic'
                                              onClick={() => handeSql(index)}>{!message?.sql ? "View SQL CMD" : "View Output"}</button>
                                      <div className="flex justify-around px-2">
                                        <Tooltip content="View in table" className="bg-slate-700 text-white"
                                                 placement="left">
                                          {!tableLoading ? (
                                              <button className='text-sm text-gray-600 italic'
                                                      onClick={() => handleHTML(index, message?.sql_cmd?.replace(/\bLIMIT\s+\d+\b/g, ''))}>
                                                      <img width="28" height="28"
                                                          src="https://img.icons8.com/windows/32/table-1.png"
                                                          alt="table-1" />
                                              </button>
                                          ) : (
                                              <span className="flex items-center">
                                      <span className="animate-pulse text-lg font-bold">...</span>
                                    </span>
                                          )}
                                        </Tooltip>
                                        <Tooltip  className="bg-slate-700 text-white" placement="left">
                                        {graphLoading ? (
                                                    <span className="flex items-center">
                                                      <span className="animate-pulse text-lg font-bold">...</span>
                                                    </span>
                                                  ) : (
                                                    <>
                                                      <button className='text-sm text-gray-600 italic' onClick={() => {handleGraph(index, message?.sql_cmd?.replace(/\bLIMIT\s+\d+\b/g, ''));handleButtonClick('line')}}>
                                                        <FontAwesomeIcon icon={faChartLine} className="mr-2 w-6" />
                                                      </button>
                                                      <button className='text-sm text-gray-600 italic' onClick={() => {handleGraph(index, message?.sql_cmd?.replace(/\bLIMIT\s+\d+\b/g, ''));handleButtonClick('pie')}}>
                                                        <FontAwesomeIcon icon={faChartPie} className="mr-2 w-4" />
                                                      </button>
                                                      <button className='text-sm text-gray-600 italic' onClick={() => {handleGraph(index, message?.sql_cmd?.replace(/\bLIMIT\s+\d+\b/g, ''));handleButtonClick('bar')}}>
                                                        <FontAwesomeIcon icon={faChartBar} className="mr-2 w-4" />
                                                      </button>
                                                    </>
                                                  )}

                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </>
                          ) : (
                              <>
                                <div className="flex-grow">
                                  <div className="bg-gradient-to-r from-blue-500 to-blue-300 text-white rounded-lg p-2">
                                    <p className="text-sm">{message.content}</p>
                                  </div>
                                </div>
                                <img
                                    className="w-8 h-8 rounded-full ml-2"
                                    src="https://cdn-icons-png.flaticon.com/64/4322/4322991.png"
                                    alt="User 2"
                                />
                              </>
                          )}
                        </div>
                    ))}
                  </div>
              )}
    </div>
    
          
    {/* Chat input */}
    <div className="flex items-center">
          <input
            type="text"
            className="flex-grow border border-gray-300 rounded-lg px-4 py-2 mr-2 focus:outline-none"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            disabled={isLoading}
            onKeyPress={handleKeyPress}
          />
          <button
            className={`bg-blue-500 text-white rounded-lg px-4 py-2 focus:outline-none ${
              isLoading ? 'opacity-50 cursor-wait' : ''
            }`}
            onClick={handleMessageSend}
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="flex items-center">
                <span className="animate-pulse text-lg font-bold">...</span>
              </span>
            ) : (
              'Send'
            )}
          </button>
    </div>
  </main>
  <h5 className="text-center mt-5 text-gray-400">View the underlying database <a href={`sqlite/?url=${window.location.href}/sqlite/Chinook.db`} className='text-blue-500'>here</a> </h5>
</div>
</div>
  );
}

export default App;




